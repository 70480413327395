import React from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";
import ReactAudioPlayer from "react-audio-player";

import Layout from "../components/Layout";
import PortableText from "../components/PortableText";

export const query = graphql`
  query EpisodeQuery($id: String!) {
    sanityEpisode(id: { eq: $id }) {
      title
      coverImage {
        asset {
          id
          originalFilename
          url
        }
        altText
      }
      _rawSpeakerBio
      audioFile {
        asset {
          url
        }
      }
    }
  }
`;

export default function Episode({ data: { sanityEpisode } }) {
  return (
    <Layout title="Episode" page="episode" url="/episode">
      {sanityEpisode.title && (
        <h1 className="font-h1">{sanityEpisode.title}</h1>
      )}
      {sanityEpisode.coverImage ? (
        <Image image={sanityEpisode.coverImage} className="cover-image" />
      ) : (
        <div className="cover-image empty"></div>
      )}
      {sanityEpisode.audioFile && (
        <ReactAudioPlayer src={sanityEpisode.audioFile.asset.url} controls />
      )}
      {sanityEpisode._rawSpeakerBio && (
        <PortableText blocks={sanityEpisode._rawSpeakerBio} />
      )}
    </Layout>
  );
}
